<template>
  <div>
    <div class="container">
      <div class="row m-12 justify-content-center align-items-center">
        <div class="col-12 align-content-center justify-content-center">
          <h3>Лента Сюжетов
            <span>
              <a href="javascript:void(0)" v-b-modal.HelpForm data-toggle="modal" data-target="#HelpForm"
                style="border: none;" class="text-primary"><help-circle-icon class="fea icon-sm"></help-circle-icon></a>
            </span>
            <b-modal id="HelpForm" title="Справка по сервису" centered button-size="sm" size="lg" ok-only>

              <div class="bg-white p-1 rounded box-shadow">
                <div class="text-muted mb-2" style="font-size: small;">
                  <p>
                    На данной странице можно увидеть историю развития сюжета.
                  </p>

                  <p>
                    В шапке располагается текущее названию сюжета и время когда он был последний раз обновлен.
                  </p>

                  <p>
                    При нажатии на кнопку "Показать примеры сообщений" можно посмотреть примеры собщений в сюжете.
                  </p>

                  <p>
                    Ниже в таблице представлена вся цепочка связанных кластеров - дата создания и название. Кластера
                    считаем связанными если у них пересекаются больше 50% сообщений.
                  </p>

                  <p>
                    Количество сообщений в кластере на опеределенный момент времени также отражен на графике. Важно
                    помнить что мы движемся с окном в 24 часа - поэтому число на зеленом графике показывает сумму
                    сообщений за последние 24 часа.
                    Разбивка непосредственно по часам представлена на синем графике - это разбивка всех уникальных
                    сообщений всех связанных кластеров по часам.
                  </p>
                </div>
              </div>
            </b-modal>
          </h3>
        </div>
        <div class="col-8 align-content-center justify-content-center">
          <h5 class="text-muted mb-0">
            {{ first_summary }}
          </h5>
          <p class="text-muted mb-0 font-italic" style="font-size: small;" align="right" v-if="first_summary_date">
            Обновлено в {{ first_summary_date.split(' ')[1] + ' ' + first_summary_date.split(' ')[0] }}
          </p>
        </div>
        <div class="col-4 align-content-center justify-content-center text-info" style="font-size: small;">
          <ul>
            <li>Максимальная история на графике: <b>24 часа</b></li>
            <li>"Несодержательные" сюжеты <b>не</b> учитываем</li>
            <li>Соседние строки таблицы, у которых одна дата, соответствуют разным сюжетам одной итерации сюжетирования
            </li>
          </ul>
        </div>
        <div class="col-12 align-content-left justify-content-left">
          <a class="btn btn-outline-secondary mt-2 mr-2 mb-2" @click="toggleOpened()">
            <span v-if="opened">
              Скрыть примеры сообщений
            </span>
            <span v-else>
              Показать примеры сообщений
            </span>
          </a>
        </div>
        <div v-if="opened">
          <div class="table-responsive bg-white shadow rounded">
            <div style="max-height:20em; width: 100%; overflow-y:scroll; overflow-x:auto;">
              <table class="table mb-0 table-center text-secondary"
                style="font-size:small; width:100%; table-layout:fixed;" rules="groups">
                <colgroup>
                  <col span="1" style="width:90%;">
                  <col span="1" style="width:5%;">
                </colgroup>
                <tbody>
                  <template v-for="(m, m_id) in cluster_messages">
                    <tr style="background-color: #1f2d3d !important; color: #5a6d90 !important;">
                      <td style="word-wrap: break-word">{{ m[0] }}</td>
                      <td>
                        <a style="pointer-events: auto;" :href=m[1] target="_blank">ссылка</a>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="col-12">
          <apexchart ref="theChart" type="line" height="350" :options="chartOptions" :series="series"></apexchart>
        </div>
      </div>
      <div class="row m-12 justify-content-center align-items-center">
        <div class="col-12">
          <div class="table-responsive bg-white shadow rounded">
            <table class="table mb-0 table-center" style="width:100%;">
              <thead>
                <tr>
                  <th scope="col" style="width:13%; text-align:center;" @click="sort('date')">
                    Дата
                    <span v-if="currentSort === 'date'">
                      <span v-if="currentSortDir === 'asc'">
                        <arrow-up-icon class="fea icon-sm"></arrow-up-icon>
                      </span>
                      <span v-else-if="currentSortDir === 'desc'">
                        <arrow-down-icon class="fea icon-sm"></arrow-down-icon>
                      </span>
                    </span>
                  </th>
                  <th scope="col" style="width:10%; text-align:center;">Число сообщений</th>
                  <th scope="col" style="width:78%; text-align:center;">Название сюжета</th>
                </tr>
              </thead>
              <tbody>
                <template v-for="x in sorted_history_list">
                  <tr>
                    <td colspan="3">
                      <table class="table mb-0 table-center" style="width:100%; font-size: small">
                        <template v-for="(y, ix) in x.data">
                          <tr v-if="ix === 0">
                            <td rowspan="100" style="width:13%; border-top: none !important; text-align:center;">
                              {{ x.date }}</td>
                            <td style="width:10%; border-top: none !important; text-align:center;">{{ y[2] }}</td>
                            <td style="width:78%; border-top: none !important;">{{ y[1] }}</td>
                          </tr>
                          <tr v-else>
                            <td style="width:5%">{{ y[2] }}</td>
                            <td style="width:75%">{{ y[1] }}</td>
                          </tr>
                        </template>
                      </table>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import store from "@/store/store";

import { ArrowUpIcon, ArrowDownIcon, HelpCircleIcon } from "vue-feather-icons";

export default {
  name: "topic-modelling-history",
  components: {
    ArrowUpIcon,
    ArrowDownIcon,
    HelpCircleIcon
  },
  data() {
    return {
      history_list: [],
      first_summary: '',
      first_summary_date: '',
      currentSort: 'date',
      currentSortDir: 'asc',
      opened: false,
      series: [
        {
          name: "Сообщений в сюжете за час",
          data: [],
          type: 'column',
        },
        {
          name: "Сообщений в кластере",
          data: [],
          type: 'line',
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: 'История сюжета',
          align: 'left'
        },
        xaxis: {
          type: 'datetime',
          labels: {
            format: 'd MMM, HH:mm',
            datetimeUTC: false
          }
        },
        yaxis: [
          {
            seriesName: "Сообщений в кластере",
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: "Количество сообщений",
            },
          },
          {
            seriesName: "Сообщений в кластере",
            show: false,
          },
        ],
        tooltip: {
          shared: true,
          // intersect: true,
          x: {
            format: 'd MMM, HH:mm',
            // show: false
          }
        },
      },
    };
  },

  mounted() {
    this.getData();
    console.log('data is ready');
  },
  methods: {
    toggleOnLoad() {
      this.$refs.theChart.toggleSeries("Сообщений в кластере")
    },
    getHostname(stream) {
      let basic_url = ''
      if (stream == "SberBA") {
        basic_url = basic_url + this.$hostname_ba
      } else {
        basic_url = basic_url + this.$hostname_ba_macro
      }
      return basic_url;
    },
    getData() {
      let requestData = {
        clusterizationId: parseInt(this.$route.params.clusterization_id),
        clusterId: parseInt(this.$route.params.cluster_id)
      };
      this.axios({
        method: "post",
        url: this.getHostname(this.$route.params.stream) + "/plot-modelling/get-clusters-history",
        data: requestData,
      }).then((res) => {
        this.history_list = res.data.cluster_stats;
        this.msg_bins = res.data.msg_stats;
        this.cluster_messages = res.data.cluster_messages;

        let cluster_plot_data = [];
        for (let i = this.history_list.length - 1; i >= 0; i -= 1) {
          cluster_plot_data.push({
            x: this.history_list[i].date,
            y: this.history_list[i].total_count
          });
        }
        this.series = [
          {
            name: "Сообщений в сюжете за час",
            data: this.msg_bins,
            type: 'column',
          },
          {
            name: "Сообщений в кластере",
            data: cluster_plot_data,
            type: 'line',
          },
        ];
        //console.log(this.series);
        this.first_summary = this.history_list[0].data[0][1];
        this.first_summary_date = this.history_list[0].date;
        this.toggleOnLoad();
      });
    },
    sort: function (s) {
      //if s == current sort, reverse
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = s;
    },
    toggleOpened() {
      this.opened = ~this.opened;
    },
  },
  computed: {
    sorted_history_list: function () {
      return this.history_list.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'desc') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  },
};
</script>

<style>
svg>text {
  fill: white;
}

.apexcharts-tooltip.apexcharts-theme-light {
  background: rgb(31 45 61)
}

.apexcharts-text tspan {
  fill: white;
}

.apexcharts-menu {
  background: rgb(31 45 61);
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: rgb(51 65 81)
}

.apexcharts-legend-text {
  color: white !important;
}
</style>